// layout reference: https://www.w3schools.com/howto/howto_css_blog_layout.asp
import React, { useEffect, useState } from "react";
import './posts.css';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [newpost, setNewpost] = useState({
    title: "",
    name: "",
    content: ""
  });
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://worker.justwang.workers.dev/",
        {
          "metod": "GET",
        }
      );
      const postsResp = await resp.json();
      setPosts(postsResp);
    };

    getPosts();
  }, [submit]);


  const handleSubmit = async () => {
    const data = JSON.stringify(newpost)
    const res = await fetch("https://worker.justwang.workers.dev/", {
      method: 'POST',
      headers: {
        'Accept':'plain text',
        'Content-Type': 'plain text',
      },
      body: JSON.stringify({
        firstParam: 'yourValue',
        secondParam: 'yourOtherValue',
      })
    })
    const postsResp = await res.json();
    setSubmit(!submit)
  }

  return (
    <div>
      <div class="header">
        <h1>Justin's Blog</h1>
      </div>
      {/* <div class="card">
        <form onSubmit={handleSubmit}>
          <label>
            Title:
            <br /><input
              onChange={e => setNewpost({ ...newpost, title: e.target.value })}
              value={newpost.title}
              type="text"
              name="title" /> <br />
            Name:
            <br /><input
              onChange={e => setNewpost({ ...newpost, name: e.target.value })}
              value={newpost.name}
              type="text"
              name="name" /> <br />
            Content:
            <br /><textarea
              onChange={e => setNewpost({ ...newpost, content: e.target.value })}
              value={newpost.content}
              name="content"
              rows="5"
              cols="33" /><br />
          </label>
          <input type="submit" value="Post!" />
        </form>
      </div> */}
      <div class="row">
        {posts.map((post) => (
          <div class="card">
            <h2>{post.title}</h2>
            <h5>author: {post.username}</h5>
            <p>{post.content}</p>
          </div>
        ))}
      </div>
    </div >
  );
};

export default Posts;